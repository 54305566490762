<template>
  <div>
    <v-data-table
      ref="dataTable"
      :loading="isLoading || isLoadingTimecards"
      :headers="TimecardHeaders"
      :items="timecards"
      :header-props="{ sortByText: 'ソート' }"
      :footer-props="{ itemsPerPageText: '行/ページ:' }"
      no-data-text="データがありません"
      :page="$route.query.page ? Number($route.query.page) : 1"
      @update:page="($event) => {
        $vuetify.goTo($refs.dataTable)
        addQuery({ page: $event })
      }"
    >
      <template #loading>
        <v-skeleton-loader type="table" />
      </template>

      <template #top>
        <div class="w-full d-flex align-center">
          <v-menu
            v-model="isFilterMenuOpen"
            :close-on-content-click="false"
            min-width="300"
            offset-y
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                class="ml-2"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiFilterMenuOutline }}
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-subheader>ユーザー</v-subheader>
              <v-list-item>
                <v-list-item-content class="px-2">
                  <user-select
                    :users="users.flatMap(user => user.attributes.role !== 'alliance' ? user : [])"
                    :role-order="['cast', 'waiter']"
                    :prepend-inner-icon="icons.mdiAccountCircleOutline"
                    class="pa-0 ma-0"
                    @selected="getTimecards({ ...$event, ...$route.query })"
                    @cleared="getTimecards($route.query)"
                  />
                </v-list-item-content>
              </v-list-item>

              <v-subheader>ロール</v-subheader>
              <v-list-item>
                <v-list-item-content class="px-2">
                  <simple-select
                    :items="[
                      { id: 'cast', attributes: { name: 'キャスト' } },
                      { id: 'waiter', attributes: { name: 'ボーイ' } },
                    ]"
                    item-id-key-name="role"
                    :prepend-inner-icon="icons.mdiCardAccountDetailsOutline"
                    class="pa-0 ma-0"
                    @selected="getTimecards({ ...$event, ...$route.query })"
                    @cleared="getTimecards($route.query)"
                  />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer />
          <v-btn
            :loading="isLoading"
            :disabled="timecards.length <= 0 || isLoading"
            :ripple="false"
            color="secondary"
            small
            class="ma-2"
            @click="download"
          >
            <v-icon left>
              {{ icons.mdiFileExcel }}
            </v-icon>
            ダウンロード
          </v-btn>
        </div>
      </template>

      <template #[`item.attributes.startAt`]="{item}">
        <yona-edit-dialog
          :key="`timecard=${item.id}-startAt-${item.attributes.startAt}`"
          :display-name="item.attributes.startAt ? hhmm(item.attributes.startAt) : '未'"
          :is-valid="!hasErrorInput.timecardStartAt"
          @open="edit({ id: item.id, startAt: item.attributes.startAt ? dateTimeForJsFriendly(item.attributes.startAt) : dateTimeForJsFriendly(new Date()) })"
          @save="update"
        >
          <template #input>
            <v-text-field
              ref="timecardStartAtInput"
              v-model="editTarget.startAt"
              type="datetime-local"
              :max="item.attributes.leaveAt ? dateTimeForJsFriendly(item.attributes.leaveAt) : ''"
              :rules="[
                () => {
                  if (!item.attributes.leaveAt) return true
                  if (editTarget.startAt && item.attributes.leaveAt) {
                    if (dateTimeForJsFriendly(editTarget.startAt.split(' ').join('T')) > dateTimeForJsFriendly(item.attributes.leaveAt.split(' ').join('T'))) return '退勤時刻より前の時刻を入力してください'
                  }

                  return true
                },
              ]"
              class="pa-0 ma-0"
            />
          </template>
        </yona-edit-dialog>
      </template>

      <template #[`item.attributes.leaveAt`]="{item}">
        <yona-edit-dialog
          :key="`timecard=${item.id}-leaveAt-${item.attributes.leaveAt}`"
          :display-name="item.attributes.leaveAt ? hhmm(item.attributes.leaveAt) : '未'"
          :is-valid="!hasErrorInput.timecardLeaveAt"
          @open="edit({ id: item.id, leaveAt: item.attributes.leaveAt ? dateTimeForJsFriendly(item.attributes.leaveAt) : dateTimeForJsFriendly(new Date()) })"
          @save="update"
        >
          <template #input>
            <v-text-field
              ref="timecardLeaveAtInput"
              v-model="editTarget.leaveAt"
              type="datetime-local"
              :min="dateTimeForJsFriendly(item.attributes.startAt)"
              :rules="[
                () => {
                  if (editTarget.leaveAt && item.attributes.startAt) {
                    if (dateTimeForJsFriendly(editTarget.leaveAt.split(' ').join('T')) < dateTimeForJsFriendly(item.attributes.startAt.split(' ').join('T'))) return '出勤時刻より後の時刻を入力してください'
                  }

                  return true
                },
              ]"
              class="pa-0 ma-0"
            />
          </template>
        </yona-edit-dialog>
      </template>

      <template #[`item.attributes.breakCards.data`]="{item}">
        <yona-edit-dialog
          :key="`timecard=${item.id}-breakTimecards`"
          :is-valid="!hasErrorInput.breakTimecardStartAt && !hasErrorInput.breakTimecardEndAt"
          @save="update"
          @close="cancelEdit"
        >
          <template #append-outer-display-name>
            <v-chip-group
              v-if="item.attributes.breakTimecards.data && item.attributes.breakTimecards.data.length > 0"
              :key="`timecard=${item.id}-breakTimecards-${JSON.stringify(item.attributes.breakTimecards.data)}`"
              column
              small-chips
            >
              <v-chip
                v-for="breakTimecard in item.attributes.breakTimecards.data"
                :key="`breakTimecard${breakTimecard.id}-${breakTimecard.attributes.startAt}-${breakTimecard.attributes.endAt}`"
                small
                @click="edit({
                  id: item.id,
                  breakTimecardsAttributes: [{
                    id: breakTimecard.id,
                    breakTimeStartAt: dateTimeForJsFriendly(breakTimecard.attributes.breakTimeStartAt ? breakTimecard.attributes.breakTimeStartAt : item.attributes.startAt),
                    breakTimeEndAt: dateTimeForJsFriendly(breakTimecard.attributes.breakTimeEndAt ? breakTimecard.attributes.breakTimeEndAt : item.attributes.startAt),
                  }]
                })"
              >
                <span v-if="breakTimecard.attributes.breakTimeStartAt">
                  {{ hhmm(breakTimecard.attributes.breakTimeStartAt) }}
                </span>
                <span class="mx-3">~</span>
                <span v-if="breakTimecard.attributes.breakTimeEndAt">
                  {{ hhmm(breakTimecard.attributes.breakTimeEndAt) }}
                </span>
              </v-chip>
            </v-chip-group>
          </template>

          <template #input>
            <div>
              <span
                v-if="isEditingHere(item.id, 'breakTimecardsAttributes[0]')"
              >
                <v-text-field
                  ref="breakTimecardStartAtInput"
                  v-model="editTarget.breakTimecardsAttributes[0].breakTimeStartAt"
                  type="datetime-local"
                  label="IN"
                  :min="dateTimeForJsFriendly(item.attributes.startAt)"
                  :rules="[
                    () => {
                      if (dateTimeForJsFriendly(editTarget.breakTimecardsAttributes[0].breakTimeStartAt.split(' ').join('T')) < dateTimeForJsFriendly(item.attributes.startAt.split(' ').join('T'))) {
                        return '出勤時刻より後の時刻を入力してください'
                      }

                      return true
                    },
                  ]"
                  class="pa-0 ma-0 my-8"
                />

                <v-text-field
                  ref="breakTimecardEndAtInput"
                  v-model="editTarget.breakTimecardsAttributes[0].breakTimeEndAt"
                  type="datetime-local"
                  label="OUT"
                  :min="dateTimeForJsFriendly(editTarget.breakTimecardsAttributes[0].breakTimeStartAt)"
                  :rules="[
                    () => {
                      if (dateTimeForJsFriendly(editTarget.breakTimecardsAttributes[0].breakTimeEndAt.split(' ').join('T')) < dateTimeForJsFriendly(editTarget.breakTimecardsAttributes[0].breakTimeStartAt.split(' ').join('T'))) {
                        return '休憩入りより後の時刻を入力してください'
                      }

                      return true
                    },
                  ]"
                  class="pa-0 ma-0"
                />
              </span>
            </div>
          </template>
        </yona-edit-dialog>
      </template>

      <template #[`break_timecard.attributes.breakTimeEndAt`]="{item}">
        <span v-if="item"></span>
      </template>

      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-end flex-wrap">
          <yona-edit-dialog
            title="削除の確認"
            btn-color="error"
            save-text="同意の上で削除"
            @save="destroy(item.id)"
          >
            <template #append-outer-display-name>
              <v-btn
                icon
                :ripple="false"
              >
                <v-icon small>
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </template>

            <template #input>
              <span>
                削除を実行してもよろしいですか（このタイムカードを紐づけたすべての履歴も削除します。）
              </span>
            </template>
          </yona-edit-dialog>
        </div>
      </template>

      <template #[`footer.page-text`]="props">
        {{ props.pageStart }} - {{ props.pageStop }} / {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  ref,
  computed,
  getCurrentInstance,
  inject,
} from '@vue/composition-api'
import {
  mdiCalendar,
  mdiTrashCanOutline,
  mdiAccountCircleOutline,
  mdiCardAccountDetailsOutline,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiPlus,
  mdiFileExcel,
  mdiFilterMenuOutline,
} from '@mdi/js'
import { cloneDeep } from 'lodash'
import * as XLSX from 'xlsx/xlsx.mjs'
import TimecardApi from '@/api/waiter/Timecard'
import useDateFormat from '@/views/composable/useDateFormat'
import useQueryString from '@/views/composable/useQueryString'
import useDataTableEdit from '@/views/composable/useDataTableEdit'
import useCompInit from '@/views/composable/useCompInit'
import useTimecardsDataTable from '@/views/composable/data-table/useTimecardsDataTable'
import UserSelect from '@/views/components/util/filter/UserSelect.vue'
import SimpleSelect from '@/views/components/util/filter/SimpleSelect.vue'
import YonaEditDialog from '@/views/components/util/YonaEditDialog.vue'
import { TimecardHeaders } from '@/utils/constants/table-headers'

export default {
  components: {
    UserSelect,
    SimpleSelect,
    YonaEditDialog,
  },
  props: {
    date: {
      type: String,
      required: true,
      default: () => new Date().toISOString().substr(0, 10),
    },
    interval: {
      type: Number,
      required: true,
      default: 0,
    },
    endDate: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy

    const isLoadingTimecards = ref(false)
    const timecards = ref([])
    const users = inject('users', [])
    const fetchOperationRateStats = inject('fetchOperationRateStats', () => {})
    const timecardStartAtInput = ref(null)
    const timecardLeaveAtInput = ref(null)
    const breakTimecardStartAtInput = ref(null)
    const breakTimecardEndAtInput = ref(null)
    const isDownloading = ref(false)
    const isFilterMenuOpen = ref(false)

    const { hhmm, dateTimeForJsFriendly } = useDateFormat()
    const { route, addQuery } = useQueryString()
    const {
      isEditing,
      editTarget,
      cancelEdit,
      edit,
      isEditingHere,
    } = useDataTableEdit()
    const { isLoading, initWith } = useCompInit()
    const { buildXlsxData } = useTimecardsDataTable(timecards)

    const hasErrorInput = computed(() => {
      return {
        timecardStartAt: timecardStartAtInput.value?.hasError,
        timecardLeaveAt: timecardLeaveAtInput.value?.hasError,
        breakTimecardStartAt: breakTimecardStartAtInput.value?.hasError,
        breakTimecardEndAt: breakTimecardEndAtInput.value?.hasError,
      }
    })

    const getTimecards = async ({
      date = null,
      interval = null,
      endDate = null,
      userId = null,
      role = ['cast', 'waiter'],
    }) => {
      isLoadingTimecards.value = true

      const res = await TimecardApi.getTimecards({
        date,
        interval,
        endDate,
        userId,
        role,
      })

      if (res?.status === 200) {
        timecards.value = [...res.data.timecards.data]
      }

      isLoadingTimecards.value = false
    }

    const update = async () => {
      // NOTE: 休憩時間の編集はupdateTimecardした後になぜかeditTargetの中身が空になるので
      const editTargetValue = cloneDeep(editTarget.value)

      const res = await TimecardApi.updateTimecard(editTarget.value)

      if (res?.status === 200) {
        const index = timecards.value.findIndex(o => +o.id === +editTargetValue.id)
        timecards.value.splice(index, 1, res.data.timecard.data)
        vm.$toast.success('タイムカードを更新しました')

        fetchOperationRateStats()
      }

      cancelEdit()
    }

    const destroy = async id => {
      const res = await TimecardApi.deleteTimecard(id)

      if (res) {
        const index = timecards.value.findIndex(o => +o.id === +id)
        timecards.value.splice(index, 1)
        vm.$toast.success('タイムカードを削除しました')

        fetchOperationRateStats()
      }
    }

    const download = () => {
      isDownloading.value = true

      const timecardsExcel = buildXlsxData()

      const wb = XLSX.utils.book_new()
      const data = XLSX.utils.json_to_sheet(timecardsExcel)
      XLSX.utils.book_append_sheet(wb, data, 'タイムカード')

      let title = `${props.date}_${['日', '週', '月'][props.interval]}`
      if (props.endDate) title = `${props.date}~${props.endDate}`
      XLSX.writeFile(wb, `タイムカード_${title}.xlsx`)
      isDownloading.value = false
    }

    initWith([
      getTimecards(route.value.query),
    ])

    return {
      // data
      isLoading,
      isLoadingTimecards,
      isEditing,
      timecards,
      TimecardHeaders,
      users,
      editTarget,
      timecardStartAtInput,
      timecardLeaveAtInput,
      breakTimecardStartAtInput,
      breakTimecardEndAtInput,
      isFilterMenuOpen,

      // computed
      hasErrorInput,

      // methods
      hhmm,
      addQuery,
      getTimecards,
      edit,
      cancelEdit,
      update,
      destroy,
      isEditingHere,
      dateTimeForJsFriendly,
      download,

      icons: {
        mdiCalendar,
        mdiTrashCanOutline,
        mdiAccountCircleOutline,
        mdiCardAccountDetailsOutline,
        mdiCheckCircleOutline,
        mdiCloseCircleOutline,
        mdiPlus,
        mdiFileExcel,
        mdiFilterMenuOutline,
      },
    }
  },
}
</script>
